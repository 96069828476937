import axios from "axios";

export const getApiUrl = (path) => process.env.REACT_APP_BACKEND_URL + path;
export const buildAuthorizationHeaders = (accessToken) => ({
    Authorization: 'Bearer ' + accessToken
});

export const authentifiedGet = async (accessToken, path) => await axios.get(getApiUrl(path), {
    headers: buildAuthorizationHeaders(accessToken)
});

export const authentifiedPost = async (accessToken, path, body) => await axios.post(getApiUrl(path), body, {
    headers: buildAuthorizationHeaders(accessToken)
});
