import React, { useState } from 'react';
import './Survey.scss'
import { useStore } from '../../../store/store';
import CircularCompass from './CircularCompass';
import { showPopup } from '../../../store/reducer';

export default ({ survey, questionIndex, onPageCompleted }) => {
    const [state, dispatch] = useStore();
    const [situation, setSituation] = useState(null);
    const [comment, setComment] = useState('');
    const [actual, setActual] = useState(null);
    const [expected, setExpected] = useState(null);
    const question = survey.questions[questionIndex];
    const visualQuestionNumber = questionIndex + 1;
    const isLastQuestion = visualQuestionNumber == survey.questions.length;

    const showIncompletePopup = () => {
        let content = null;
        if (actual === null && expected === null && situation === null) {
            content = "Vous devez au moins remplir l'étape 1 et l'étape 2 avant de continuer."
        } else if (actual !== null && expected === null && situation === null) {
            content = "Vous devez compléter l'étape 1 et remplir l'étape 2 avant de continuer."
        } else if (expected !== null && situation === null) {
            content = "Vous devez remplir l'étape 2 avant de continuer."
        } else if (actual === null && expected === null && situation !== null) {
            content = "Vous devez remplir l'étape 1 avant de continuer."
        } else if (actual !== null && expected === null && situation !== null) {
            content = "Vous devez compléter l'étape 1 avant de continuer."
        } else if (actual === null || expected === null || situation === null) {
            // fallback
            content = "Vous devez au moins remplir l'étape 1 et l'étape 2 avant de continuer."
        }

        if (content) {
            dispatch(showPopup({
                title: "Réponse incomplete!",
                content: () => (
                    <div>
                        {content}
                    </div>
                )
            }))
            return true;
        }

        return false;
    };



    return (
        <div className='survey-container'>
            <div className='survey-header'>
                <h3>{survey.title}</h3>
                <div className="page-counter small-text subtle">Question {visualQuestionNumber} sur {survey.questions.length}</div>
                <div className='survey-header-question'>
                    <p className='question-intro'>À l'heure actuelle, à quel point cet énoncé est-il appliqué conformément au sein de votre équipe de travail ?</p>
                    <div className='survey-question'>
                        <p><b>Item {visualQuestionNumber} :</b> {question.title}</p>
                    </div>
                    <div className='description'>
                        <p>{question.description}</p>
                        {question.customExemple && <question.customExemple />}
                    </div>
                </div>
            </div>
            
            <div>
                <div>
                    <div className='survey-item-number'>Étape 1:</div>
                    <div className='survey-light-container'>
                        <p>Identifiez sur le cadran ci-dessous:</p>

                        <ol type="A">
                            <li style={{ color: "#5f7e88" }}>
                                <div>
                                <span>
                                    À l'aide du{" "}
                                    <span
                                        style={{
                                            color: "#5F7E88",
                                            textDecoration: "underline",
                                            fontWeight: "bold"
                                        }}
                                    >
                                    curseur vert
                                    </span>
                                    , identifiez votre perception de la <b>situation actuelle</b>.
                                </span>
                                </div>
                            </li>

                            <li style={{ color: "#D02E26" }}>
                                <div>
                                    <span>
                                        À l’aide du{" "}
                                        <span
                                            style={{
                                                color: "#D02E26",
                                                textDecoration: "underline",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            curseur rouge
                                        </span>{" "}
                                        identifiez votre perception de la <b>situation souhaitée</b>, c'est-à-dire la cible à atteindre
                                        compte tenu du contexte dans lequel s'exerce votre travail et celui de vos collègues.
                                    </span>
                                </div>
                            </li>
                        </ol>
                    </div>
                    <CircularCompass handleSituationChange={setActual} handleObjectifChange={setExpected} />
                </div>
                <div>
                    <div className='survey-item-number'>Étape 2:</div>
                    <div className='survey-light-container'>
                        <p>Précisez si la situation actuelle vous semble en amélioration, stable ou en détérioration.</p>
                    </div>
                    <p>La situation actuelle semble:</p>
                    <div className='survey-btns'>
                        <button className={'btn-light ' + (situation == 0 ? 'active' : '')} onClick={() => setSituation(0)}>En amélioration</button>
                        <div className='or'>ou</div>
                        <button className={'btn-light ' + (situation == 1 ? 'active' : '')} onClick={() => setSituation(1)}>Stable</button>
                        <div className='or'>ou</div>
                        <button className={'btn-light ' + (situation == 2 ? 'active' : '')} onClick={() => setSituation(2)}>En détérioration</button>
                    </div>
                </div>
                <div>
                    <div className='survey-item-number'>Étape 3 (facultative):</div>
                    <div className='survey-light-container'>
                        <p>Justifiez vos réponses par un commentaire ou suggérez des avenues d’amélioration (solutions).</p>
                    </div>
                    <textarea placeholder='Inscrire votre commentaire ici.' value={comment} onChange={e => setComment(e.target.value)}/>
                </div>
                <div className='continue-container'>
                    <button className='btn-opaque' onClick={() => {
                        if (showIncompletePopup()) {
                            return;
                        }

                        onPageCompleted({
                            expected,
                            actual,
                            situation,
                            comment,
                            questionId: question.id
                        });
                    }}
                    >
                        {isLastQuestion ? 'Completer' : 'Continuer'}
                    </button>
                </div>
            </div>
        </div>
    );
};