import { useLocation } from 'react-router-dom';
import './Hamburger.scss';
import { useEffect, useState } from 'react';

export default ({ children }) => {
    const location = useLocation();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(false);
    }, [location]);

    return (
        <div className="hamburger">
            <div className="hamburger-icon" onClick={() => setVisible(!visible)}>
                <div className="hamburger-icon-bar"></div>
            </div>

            <div className={"hamburger-menu " + (visible ? 'visible' : '')}>
                {children}
            </div>
        </div>
    );
};
