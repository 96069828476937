import React from 'react';
import './Survey.scss'
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../hooks/useQuery';
import { answerQuestion } from '../../../api/survey';
import { getAccessToken } from '../../../store/accessors';
import withSurvey from './withSurvey';
import { addAnswer } from '../../../store/reducer';
import Survey from './Survey';
import { useStore } from '../../../store/store';

export default () => {
    const [state, dispatch] = useStore();
    const query = useQuery();
    const navigate = useNavigate();

    const unitId = query.get("unitId");
    const surveyId = query.get("surveyId");
    const survey = withSurvey(unitId, surveyId);

    if (!survey) {
        return <div />;
    }

    const questionIndex = +query.get("question");
    const question = survey.questions[questionIndex];
    const isLastQuestion = (questionIndex + 1) == survey.questions.length;

    return (
        <div className='survey main-container'>
            <Survey
                key={surveyId + questionIndex}
                survey={survey}
                questionIndex={questionIndex}
                onPageCompleted={(answer) => {
                    answerQuestion(getAccessToken(state), unitId, surveyId, question.id, answer)
                        .then(data => {
                            if (isLastQuestion) {
                                navigate('/dashboard')
                            } else {
                                navigate(`/survey?surveyId=${surveyId}&unitId=${unitId}&question=${questionIndex + 1}`);
                            }

                            dispatch(addAnswer(unitId, surveyId, data.data));
                        });
                }}
            />
        </div>
    );
};