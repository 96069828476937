import { Navigate, useNavigate } from 'react-router-dom';
import useQuery from '../../../hooks/useQuery';
import Survey from '../Survey/Survey';
import '../Survey/Survey.scss'
import { survey } from './trial-survey';
import TrialResults from '../TrialResults/TrialResults';
import { useState } from 'react';

export default () => {
    const query = useQuery();
    const navigate = useNavigate();
    const [averages, setAverages] = useState({});

    if (!!query.get('showResults')) {
        return <TrialResults survey={survey} results={{ userCount: 1, averages }} />;
    }

    const questionIndex = +query.get("question");
    if (questionIndex < 0 || questionIndex >= survey.questions.length) {
        return <Navigate to={`/survey-trial?question=${0}`} />;
    }

    const isLastQuestion = (questionIndex + 1) == survey.questions.length;

    return (
        <div className='survey trial main-container'>
            <Survey
                key={questionIndex}
                survey={survey}
                questionIndex={questionIndex}
                onPageCompleted={({
                    expected,
                    actual,
                    questionId
                }) => {
                    setAverages({
                        ...averages,
                        [questionId]: {
                            expected,
                            actual
                        }
                    });
                    
                    if (isLastQuestion) {
                        navigate(`/survey-trial?showResults=true`);
                    } else {
                        navigate(`/survey-trial?question=${questionIndex + 1}`);
                    }
                }}
            />
        </div>
    );
};