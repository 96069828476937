const SESSION_KEY = "session";

export const removeSession = () => {
    localStorage.removeItem(SESSION_KEY)
};

export const persistSession = (session) => {
    localStorage.setItem(SESSION_KEY, JSON.stringify(session))
};

export const readSession = () => {
    try {
        const item = localStorage.getItem(SESSION_KEY);
        return JSON.parse(item);
    } catch {
        removeSession();
        return null;
    }
};