import './Dashboard.scss';
import { useNavigate } from "react-router-dom";
import withDashboard from "./withDashboard";

const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
};

const SurveySeparator = ({ label }) => (
    <div className="survey-separator">
        <div className="line pre" />
        <label className="small-text subtle">{label}</label>
        <div className="line post" />
    </div>
);

const AvailableSurvey = ({ survey }) => {   
    const navigate = useNavigate();

    return (
        <div className="survey-container available">
            <div className="survey-top">
                <h4>{survey.title}</h4>
                <div className="survey-description-container">
                    <div className="description-block small-text subtle">
                        <span>{survey.unit.name}</span>
                    </div>
                    <div className="description-block small-text subtle">
                        <span>{survey.totalQuestionsCount} questions</span>
                    </div>
                    <div className="description-block small-text subtle">
                        <span>Du <b>{new Date(survey.schedule.startTimestamp).toLocaleString('fr-FR', options)}</b> au <b>{new Date(survey.schedule.endTimestamp).toLocaleString('fr-FR', options)}</b></span>
                    </div>
                </div>
            </div>
            <div className="survey-bottom" onClick={() => {
                if (survey.answeredQuestionsCount >= survey.totalQuestionsCount) {
                    navigate(`/results?surveyId=${survey.id}&unitId=${survey.unit.id}`)
                } else {
                    navigate(`/survey?surveyId=${survey.id}&unitId=${survey.unit.id}&question=${survey.answeredQuestionsCount}`)
                }
            }}>
                <div>
                    {
                        (() => {
                            const completedCount = survey.answeredQuestionsCount;
                            if (completedCount == survey.totalQuestionsCount) {
                                return 'Voir les résultats';
                            }
    
                            if (completedCount == 0) {
                                return 'Commencer le questionniaire';
                            }

                            return (
                                <>
                                    <div>Continuer le questionnaire</div>
                                    <div className="small-text subtle">{`(${completedCount} question${completedCount > 1 ? 's' : ''} complétée${completedCount > 1 ? 's' : ''} sur ${survey.totalQuestionsCount})`}</div>
                                </>
                            );
                        })()
                    }   
                </div>
            </div>
        </div>
    );
};

const Dashboard = () => {
    const dashboard = withDashboard();
    if (!dashboard) {
        return <div />;
    }

    const { surveys, user } = dashboard;

    const availableSurveys = [];
    const completedSurveys = [];

    surveys.forEach(survey => {
        if (survey.answeredQuestionsCount >= survey.totalQuestionsCount || survey.schedule.endTimestamp < Date.now()) {
            completedSurveys.push(survey);
        } else {
            availableSurveys.push(survey);
        }
    });

    return (
        <div className="dashboard main-container">
            <div className="dashboard-container">
                <h1>Bienvenue, {user.profile.firstName}!</h1>

                <SurveySeparator label="Questionnaires disponibles" />
                {
                    availableSurveys.length == 0
                        ? <div className="empty-survey-filler small-text subtle">Aucun questionnaire actuellement disponible</div>
                        : availableSurveys.map((survey, i) => <AvailableSurvey key={i} survey={survey} />)
                }

                <SurveySeparator label="Questionnaires à venir" />
                <div className="empty-survey-filler small-text subtle">Aucun futur questionnaire actuellement planifié</div>

                <SurveySeparator label="Questionnaires complétés" />
                {
                    completedSurveys.length == 0
                        ? <div className="empty-survey-filler small-text subtle">Tous les questionnaires déjà complétés apparaitront ici</div>
                        : completedSurveys.map((survey, i) => <AvailableSurvey key={i} survey={survey} />)
                }
            </div>
        </div>
    )
};

export default Dashboard;