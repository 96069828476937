import { useEffect } from 'react';
import { hidePopup } from '../../store/reducer';
import { useStore } from '../../store/store';
import './Popup.scss';

export const PopupContainer = () => {
    const [state, dispatch] = useStore();

    useEffect(() => {
        if (state.popup) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }, [!!state.popup]);

    if (!state.popup) {
        return null;
    }

    return (
        <div className="popup-container">
            <div className="popup">
                <div className="popup-header">
                    <h3 className="title">{state.popup.title}</h3>
                </div>
                <div className="popup-content">
                    <state.popup.content />
                </div>
                <div className="popup-buttons">
                    <button className="btn-opaque" onClick={() => dispatch(hidePopup())}>Retour</button>
                </div>
            </div>
        </div>
    );
};
