import useQuery from '../../../hooks/useQuery';
import withSurvey from '../Survey/withSurvey';
import './Results.scss'
import withResults from './withResults';

export default () => {
    const query = useQuery();
    const unitId = query.get("unitId");
    const surveyId = query.get("surveyId");
    const survey = withSurvey(unitId, surveyId);
    const results = withResults(unitId, surveyId);

    if (!survey || !results) {
        return <div />;
    }

    return (
        <div className='survey-results main-container'>
            <div className='results-container'>
                <div className='results-header'>
                    <h3>{survey.title}</h3>
                    <div className="counter small-text subtle">{results.userCount} répondant{results.userCount > 1 ? 's' : ''}</div>
                </div>
                {survey.questions.map((question, i) => (
                    <div key={i} className='question-result-container'>
                        <div className='question-title'><b>Item {i + 1} : </b>{question.title}</div>
                        <div className='averages-container'>
                            <div className='average-container'>
                                <div className='left small-text subtle'>Situation souhaitée</div>
                                <div className='right'>
                                    <div className='bar' style={{width: `${results.averages[question.id].expected}%`}} />
                                </div>
                                <div className='value'>{`${parseInt(results.averages[question.id].expected)}%`}</div>
                            </div>
                            <div className='average-container'>
                                <div className='left small-text subtle'>Situation actuelle</div>
                                <div className='right'>
                                    <div className='bar' style={{width: `${results.averages[question.id].actual}%`}} />
                                </div>
                                <div className='value'>{`${parseInt(results.averages[question.id].actual)}%`}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
