import { useEffect } from "react";
import { getAccessToken } from "../../../store/accessors";
import { storeSurvey } from "../../../store/reducer";
import { useStore } from "../../../store/store";
import { getSurveyDetails } from "../../../api/survey";

export default (unitId, surveyId) => {
    const [state, dispatch] = useStore();
    const survey = state.units[unitId]?.surveys[surveyId];

    useEffect(() => {
        if (survey) {
            return;
        }

        getSurveyDetails(getAccessToken(state), unitId, surveyId)
            .then(data => dispatch(storeSurvey(unitId, data.data)));
    }, [])

    return survey;
};
