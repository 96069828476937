import { useEffect, useState } from "react";
import { getAccessToken } from "../../../store/accessors";
import { useStore } from "../../../store/store";
import { getSurveyResults } from "../../../api/survey";

export default (unitId, surveyId) => {
    const [state] = useStore();
    const [resuts, setResults] = useState()

    // TODO: Save in store

    useEffect(() => {
        if (resuts) {
            return;
        }

        getSurveyResults(getAccessToken(state), unitId, surveyId)
            .then(data => setResults(data.data));
    }, [])

    return resuts;
};
