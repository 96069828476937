import { Navigate } from "react-router-dom";
import { useStore } from "../../store/store";
import { getAccessToken } from "../../store/accessors";

const SessionProtectedComponent = (props) => {
    const [state] = useStore();
    const token = getAccessToken(state);
    if (!token) {
        return <Navigate to="/login"/>;
    }

    return props.element;
}

export default SessionProtectedComponent;