import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';

import { StoreProvider } from './store/store';
import { initialState, reducer } from './store/reducer';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StoreProvider initialState={initialState} reducer={reducer}>
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>
    </StoreProvider>
);
