import { useEffect } from "react";
import { getDashboard } from "../../../api/dashboard";
import { getAccessToken } from "../../../store/accessors";
import { storeDashboard } from "../../../store/reducer";
import { useStore } from "../../../store/store";

export default () => {
    const [state, dispatch] = useStore();
    const dashboard = state.dashboard;

    useEffect(() => {
        if (dashboard) {
            return;
        }
        
        getDashboard(getAccessToken(state))
            .then(data => dispatch(storeDashboard(data.data)));
    }, [])

    return dashboard;
};
