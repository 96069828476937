import { persistSession, readSession, removeSession } from "../localStorage/session";

export const CLEAR_SESSION = 'session/clear';
export const STORE_SESSION = 'session/store';

export const STORE_DASHBOARD = 'dashboard/store';

export const STORE_SURVEY = 'survey/store';
export const ADD_ANSWER = 'survey/add_answer';

export const SHOW_POPUP = 'popup/show';
export const HIDE_POPUP = 'popup/hide';

export const initialState = {
    dashboard: null,
    session: readSession(),
    units: {},
    popup: null
};

const INITIAL_UNIT = {
    surveys: {}
};

export const clearSession = (session) => ({ type: CLEAR_SESSION, session });
export const storeSession = (session) => ({ type: STORE_SESSION, session });

export const storeDashboard = (dashboard) => ({ type: STORE_DASHBOARD, dashboard });

export const storeSurvey = (unitId, survey) => ({ type: STORE_SURVEY, unitId, survey });
export const addAnswer = (unitId, surveyId, answer) => ({ type: ADD_ANSWER, unitId, surveyId, answer });

export const showPopup = (popupData) => ({ type: SHOW_POPUP, popupData });
export const hidePopup = () => ({ type: HIDE_POPUP });

export const reducer = (state = initialState, action) => {
    if (action.type === STORE_DASHBOARD) {
        return {
            ...state,
            dashboard: action.dashboard
        };
    }
    
    if (action.type === STORE_SURVEY) {
        const unit = state.units[action.unitId] || INITIAL_UNIT;
        unit.surveys[action.survey.id] = action.survey;

        return {
            ...state,
            units: {
                ...state.units,
                [action.unitId]: unit
            }
        };
    }
    
    if (action.type === ADD_ANSWER) {
        const unit = state.units[action.unitId] || INITIAL_UNIT;
        const survey = unit.surveys[action.surveyId];
        survey.answers.push(action.answer);
        
        if (state.dashboard) {
            state.dashboard.surveys.forEach(curr => {
                if (curr.id === action.surveyId) {
                    curr.answeredQuestionsCount = survey.answers.length;
                }
            });
        }
        
        return state;
    }

    if (action.type === STORE_SESSION) {
        persistSession(action.session);
        return {
            ...state,
            session: action.session
        };
    }

    if (action.type === CLEAR_SESSION) {
        removeSession();
        return {
            ...state,
            session: null
        };
    }

    if (action.type === SHOW_POPUP) {
        return {
            ...state,
            popup: action.popupData
        };
    }

    if (action.type === HIDE_POPUP) {
        return {
            ...state,
            popup: null,
        };
    }

    return state;
};
