import { authentifiedGet, authentifiedPost } from ".";

export const getSurveyDetails = async (accessToken, unitId, surveyId) => (
    await authentifiedGet(accessToken, `/scheduled-surveys/${surveyId}?unitId=${unitId}`)
);

export const getSurveyResults = async (accessToken, unitId, surveyId) => (
    await authentifiedGet(accessToken, `/scheduled-surveys/${surveyId}/results?unitId=${unitId}`)
);

export const answerQuestion = async (accessToken, unitId, surveyId, questionId, answer) => (
    await authentifiedPost(accessToken, `/scheduled-surveys/${surveyId}/answer?unitId=${unitId}`, {
        questionId,
        answer
    })
);
