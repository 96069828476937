import React, { Component, Fragment } from "react";
import styles from "./CircularCompass.module.scss";
import SvgPath from "./SvgPath";

const noop = () => {};

const initState = {
  x: null,
  y: null,
  size: null,
  situation: {
    x: null,
    y: null
  },
  objectif: {
    x: null,
    y: null
  },
  mobileSituation: {
    x: null,
    y: null
  },
  mobileObjectif: {
    x: null,
    y: null
  },
  compassSize: 500,
};

const svgSize = 1772;
const strokeWidth = 8;

class CircularCompass extends Component {
  state = initState;

  handleMouseMove = e => {
    const secondPoint = this.getPoints(e);
    this.setState({
      x: secondPoint.x,
      y: secondPoint.y
    });
  };

  handleMouseOut = () => {
    this.setState({ x: null, y: null });
  };

  handleSelectLine = e => {
    const { center, dist, circleSize, size } = this.state;
    // get the second point of the selected line
    const secondPoint = this.getPoints(e);
    // Get the % value
    // get the angle
    const currAngle = this.getAngle(
      center,
      center,
      secondPoint.x,
      secondPoint.y
    );
    // Calculate the value between 0 and 100%
    let prcntVal = 100 - ((currAngle + 90) / 180) * 100;
    if (prcntVal > 100) {
      prcntVal = 100;
    } else if (prcntVal < 0) {
      prcntVal = 0;
    }
    // Check if the situation point is selected
    if (!this.state.situation?.x) {
      this.setState({ situation: secondPoint });
      this.props.handleSituationChange(prcntVal);
    }
    // Setup the objectif if the situation is setted
    else if (!this.state.objectif.x) {
      this.setState({ objectif: secondPoint });
      // Auto change the question after a smalltimeout
      setTimeout(() => {
        this.props.handleObjectifChange(prcntVal);
      }, 350);
    }
  };

  // MOBILE COMPASS
  mobileDrawLine = e => {
    e?.stopPropagation();

    // get the second point of the selected line
    const secondPoint = this.getPoints(e, true);

    // Check if the situation point is selected
    if (!this.state.situation?.x) {
      this.setState({ mobileSituation: secondPoint });
    }
    // Setup the objectif if the situation is setted
    else if (!this.state.objectif.x) {
      this.setState({ mobileObjectif: secondPoint });
    }
  };

  mobileSelectLine = async e => {
    e.stopPropagation();

    const { center } = this.state;
    // get the second point of the selected line
    const secondPoint = this.getPoints(e, true);
    // Get the % value
    // get the angle
    const currAngle = this.getAngle(
      center,
      center,
      secondPoint.x,
      secondPoint.y
    );

    // Calculate the value between 0 and 100%
    let prcntVal = 100 - ((currAngle + 90) / 180) * 100;
    if (prcntVal > 100) {
      prcntVal = 100;
    } else if (prcntVal < 0) {
      prcntVal = 0;
    }

    // Check if the situation point is selected
    if (!this.state.situation?.x) {
      this.setState({ situation: secondPoint, mobileSituation: {x: null, y: null} });
      this.props.handleSituationChange(prcntVal);
    }
    // Setup the objectif if the situation is setted
    else if (!this.state.objectif.x) {
      this.setState({ objectif: secondPoint, mobileObjectif: {x: null, y: null} });
      // Auto change the question after a smalltimeout
      setTimeout(() => {
        this.props.handleObjectifChange(prcntVal);
      }, 350);
    }
  };

  restartCompass = async () => {
    // restart to the initial state on button click
    this.setState(initState);
    await this.updateWindowDimensions();
    this.setupSize();
    // reset the main state
    const { handleSituationChange, handleObjectifChange } = this.props;
    handleSituationChange(null);
    handleObjectifChange(null);
  };

  componentDidMount() {
    // Setup if the user is on mobile or not
    this.setState({ isMobile: this.isMobile() });
    // Check the window width size
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    // Setup the compass
    this.setupCompass();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  isMobile = () => {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  };

  updateWindowDimensions = () => {
    let compassSize = 500;
    if (window.innerWidth < 300) {
      compassSize = 300;
    } else if (window.innerWidth < 700) {
      compassSize = 400;
    }
    this.setState({ compassSize });
  };

  setupCompass = async () => {
    await this.setupSize();
    
    const situationPrcnt = this.props.value?.situation;
    const situationPoints = this.prcntToPoint(situationPrcnt);
    const objectifPrcnt = this.props.value?.objectif;
    const objectifPoints = this.prcntToPoint(objectifPrcnt);
    this.setState({
      situation: situationPoints,
      objectif: objectifPoints
    });
  };

  prcntToPoint = prcnt => {
    if (prcnt === null || prcnt === undefined) {
      return {
        x: null,
        y: null,
      }
    }
    
    const { dist, size } = this.state;
    // Calculate the points from angle, center and length of the line
    const currAngle = ((100 - prcnt) / 100) * 180 - 90;
    const x2 = Math.cos((currAngle * Math.PI) / 180) * dist + size / 2;
    const y2 = Math.sin((currAngle * Math.PI) / 180) * dist + size / 2;
    return {
      x: x2,
      y: y2
    };
  };

  getPoints = (e, mobile = false) => {
    // This function get the coordinates of the line relative
    const svg = document.querySelector("#circularCompass");
    var pt = svg.createSVGPoint(); // Created once for document
    if (mobile) {
      pt.x = e.changedTouches[e.changedTouches.length - 1].clientX;
      pt.y = e.changedTouches[e.changedTouches.length - 1].clientY;
    } else {
      pt.x = e.clientX;
      pt.y = e.clientY;
    }
    // The cursor point, translated into svg coordinates
    var cursorpt = pt.matrixTransform(svg.getScreenCTM().inverse());
    return this.getSecondPoint(cursorpt.x, cursorpt.y);
  };

  setupSize = () => {
    const size = svgSize;
    const center = size / 2;
    const dist = size * 0.255;
    const circleSize = dist - 10;

    this.setState({
      size,
      center,
      dist,
      circleSize
    });
  };

  getAngle = (cx, cy, ex, ey) => {
    // Get the angle from 2 points
    var dy = ey - cy;
    var dx = ex - cx;
    var theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    //if (theta < 0) theta = 360 + theta; // range [0, 360)
    return theta;
  };

  getSecondPoint = (ex, ey) => {
    // Get the second point to draw the line
    const { center, dist } = this.state;
    const centerX = center;
    const centerY = center;
    // First get the angle
    var dy = ey - centerY;
    var dx = ex - centerX;
    var theta = Math.atan2(dy, dx); // range (-PI, PI]
    let angle = (theta * 180) / Math.PI; // rads to degs, range (-180, 180]
    //if (theta < 0) theta = 360 + theta; // range [0, 360)

    const maxAngle = 115;
    if (angle >= maxAngle) {
      angle = maxAngle;
    } else if (angle < -maxAngle) {
      angle = -maxAngle;
    }
    const x = Math.cos((angle * Math.PI) / 180) * dist + centerX;
    const y = Math.sin((angle * Math.PI) / 180) * dist + centerY;
    return { x, y };
  };

  render() {
    const {
      x,
      y,
      situation,
      objectif,
      mobileSituation,
      mobileObjectif,
      compassSize,
      isMobile
    } = this.state;

    let rightText = (
      <span>
        Déplacez le curseur autour du cadran pour identifier la{" "}
        <span
          style={{
            color: "#5F7E88",
            textDecoration: "underline",
            fontWeight: "bold"
          }}
        >
          situation actuelle
        </span>
        .
      </span>
    );

    let letter = (
      <span className={styles.firstLetter} style={{ color: "#5F7E88" }}>
        A.{" "}
      </span>
    );

    if (situation?.x) {
      rightText = (
        <span>
          Déplacez le curseur autour du cadran pour identifier la{" "}
          <span
            style={{
              color: "#d02e26",
              textDecoration: "underline",
              fontWeight: "bold"
            }}
          >
            la situation souhaitée
          </span>
          .
        </span>
      );
      letter = (
        <span className={styles.firstLetter} style={{ color: "#d02e26" }}>
          B.{" "}
        </span>
      );
    }
    let compassSvgClass = styles.noSelect;
    if (objectif.x) {
      rightText = <span>Vous avez complété l'étape 1.</span>;
      letter = "";
      // remove the touch-action none if the user have complete the compass
      compassSvgClass = "";
    }

    return (
      <div className={styles.compassLayout}>
        <div className={styles.compassWrapper}>
          <svg
            className={compassSvgClass}
            id="circularCompass"
            onMouseMove={(!isMobile && this.handleMouseMove) || noop}
            onMouseDown={(!isMobile && this.handleMouseMove) || noop}
            onMouseLeave={(!isMobile && this.handleMouseOut) || noop}
            onClick={(!isMobile && this.handleSelectLine) || noop}
            onTouchStart={(isMobile && this.mobileDrawLine) || noop}
            onTouchMove={(isMobile && this.mobileDrawLine) || noop}
            onTouchEnd={(isMobile && this.mobileSelectLine) || noop}
            width={`${compassSize}px`}
            height={`${compassSize}px`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1772 1772"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g id="Cadran">
                <SvgPath />
              </g>

              {situation?.x && (
                <Fragment>
                  <line
                    x1={situation?.x}
                    y1={situation.y}
                    x2={svgSize / 2}
                    y2={svgSize / 2}
                    strokeWidth={strokeWidth}
                    stroke="#5F7E88"
                    strokeLinecap="round"
                  />
                  <circle
                    cx={situation?.x}
                    cy={situation.y}
                    r="17"
                    fill="#5F7E88"
                  />
                </Fragment>
              )}

              {mobileSituation.x && (
                <Fragment>
                  <line
                    x1={mobileSituation.x}
                    y1={mobileSituation.y}
                    x2={svgSize / 2}
                    y2={svgSize / 2}
                    strokeWidth={strokeWidth}
                    stroke="#5F7E88"
                    strokeLinecap="round"
                  />
                  <circle
                    cx={mobileSituation.x}
                    cy={mobileSituation.y}
                    r="17"
                    fill="#5F7E88"
                  />
                </Fragment>
              )}

              {x && !objectif.x && (
                <Fragment>
                  <line
                    x1={x}
                    y1={y}
                    x2="886"
                    y2="886"
                    strokeWidth={strokeWidth}
                    stroke={situation?.x ? "#D02E26" : "#5F7E88"}
                    strokeLinecap="round"
                  />
                  <circle
                    cx={x}
                    cy={y}
                    r="17"
                    fill={situation?.x ? "#D02E26" : "#5F7E88"}
                  />
                </Fragment>
              )}

              {objectif.x && (
                <Fragment>
                  <line
                    x1={objectif.x}
                    y1={objectif.y}
                    x2={svgSize / 2}
                    y2={svgSize / 2}
                    strokeWidth={strokeWidth}
                    stroke="#D02E26"
                    strokeLinecap="round"
                  />
                  <circle
                    cx={objectif.x}
                    cy={objectif.y}
                    r="17"
                    fill="#D02E26"
                  />
                </Fragment>
              )}

              {mobileObjectif.x && (
                <Fragment>
                  <line
                    x1={mobileObjectif.x}
                    y1={mobileObjectif.y}
                    x2={svgSize / 2}
                    y2={svgSize / 2}
                    strokeWidth={strokeWidth}
                    stroke="#D02E26"
                    strokeLinecap="round"
                  />
                  <circle
                    cx={mobileObjectif.x}
                    cy={mobileObjectif.y}
                    r="17"
                    fill="#D02E26"
                  />
                </Fragment>
              )}

              <circle cx={svgSize / 2} cy={svgSize / 2} r="19" fill="#5f7e88" />
            </g>
          </svg>
        </div>

        {situation?.x ? (
          <button
            className={`btn btn-outlined ${styles.restartBtn}`}
            onClick={this.restartCompass}
          >
            Recommencer
          </button>
        ) : null}

        <div className={styles.rightText}>
          {letter}
          {rightText}
        </div>
      </div>
    );
  }
}

export default CircularCompass;
